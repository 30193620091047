import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionSixQueryQuery } from "../../graphql"
import { Colors } from "../../styles/colors"
import {
  aspectRatioMediaQueries,
  mediaQueries,
} from "../../styles/mediaQueries"
import { fullWidth } from "../../styles/positions"
import { StyledP } from "../../styles/typography"
import FullScreenContainer from "../full-screen-container"

import art from "../../images/section-6/123_art.svg"
import twitter from "../../images/social/twitter.png"
import blog from "../../images/social/blog.png"
import facebook from "../../images/social/facebook.png"
import instagram from "../../images/social/instagram.png"

interface Section6Props {}

const Section = ({}: Section6Props) => {
  const { background }: SectionSixQueryQuery = useStaticQuery(
    graphql`
      query SectionSixQuery {
        background: file(relativePath: { eq: "section-6/bg_wide.jpg" }) {
          ...posterImage
        }
      }
    `,
  )
  return (
    <div>
      <StyledSection
        id="section-6"
        title="Contact"
        backgroundImage={background.childImageSharp.fluid.src}
        alignment="top"
      >
        <div className="copy">
          <H2>Contact</H2>
          <Art src={art} />
          <P>
            123 Organic Tequila
            <br />
            Amatitan, Jalisco, Mexico
          </P>
          <A1 href="mailto:info@123spirits.com">info@123spirits.com</A1>
          <A2 href="http://www.123spirits.com">123spirits.com</A2>
          <SocialIcons>
            <a
              href="https://www.instagram.com/123organictequila"
              target="_blank"
            >
              <img className="instagram" src={instagram} />
            </a>
            <a
              href="https://www.facebook.com/123OrganicTequila/"
              target="_blank"
            >
              <img className="facebook" src={facebook} />
            </a>
            <a href="https://twitter.com/123Tequila" target="_blank">
              <img className="twitter" src={twitter} />
            </a>
            <a href="http://tequilaorganic.blogspot.com/" target="_blank">
              <img className="blog" src={blog} />
            </a>
          </SocialIcons>
        </div>
      </StyledSection>
      <Footer>
        <StyledP>© 2021 by 123 Spirits</StyledP>
      </Footer>
    </div>
  )
}

const H2 = styled.h2`
  font-family: "D-DIN Condensed", Helvetica;
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  letter-spacing: 1.5px;
  margin: 0 auto 12px;
  color: ${Colors.BROWN};
`

const P = styled(StyledP)`
  font-size: 21px;
  line-height: 1.5;
  color: ${Colors.BROWN};
`

const A1 = styled.a`
  display: block;
  font-family: "D-DIN", Helvetica;
  font-weight: normal;
  font-size: 21px;
  text-decoration: none;
  margin: 1em;
  color: ${Colors.BROWN};
`

const A2 = styled.a`
  display: block;
  font-family: "D-DIN Condensed", Helvetica;
  font-weight: bold;
  font-size: 38px;
  letter-spacing: 1.5px;
  text-decoration: none;
  color: ${Colors.BROWN};
`

const Footer = styled.footer`
  position: relative;
  width: 100%;
  background: white;
  padding: 32px;
  text-align: center;
`

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  margin: 20px auto;
  justify-content: center;

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin: 0 8px;
  }
`

const StyledSection = styled(FullScreenContainer)`
  background-color: white;
  background-position: bottom center;
  background-size: 100%;
  background-repeat: no-repeat;

  ${mediaQueries("tablet")`
        height: auto;
    `};

  .copy {
    max-height: 100%;
    width: 100%;
    position: relative;
    padding: 48px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;

    ${mediaQueries("tablet")`
            padding: 128px 0 50vw;
        `};

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      pointer-event: none;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: -1;
    }
  }
`

const Art = styled.img`
  margin-top: 30px;
  width: 800px;
  max-width: 100%;
  max-height: 30vh;
`

export default Section
