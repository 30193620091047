import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionFiveQueryQuery } from "../../graphql"
import { Colors } from "../../styles/colors"
import {
  aspectRatioMediaQueries,
  mediaQueries,
} from "../../styles/mediaQueries"
import { StyledH1 } from "../../styles/typography"
import FullScreenContainer from "../full-screen-container"
import YoutubeDark from "../../images/section-5/yt_icon_mono_light.png"
import YoutubeColor from "../../images/section-5/yt_icon_rgb.png"
import { centered } from "../../styles/positions"

import logo from "../../images/logo.svg"

interface HomeProps {}

const Section = ({}: HomeProps) => {
  const { poster }: SectionFiveQueryQuery = useStaticQuery(
    graphql`
      query SectionFiveQuery {
        poster: file(relativePath: { eq: "section-5/poster.jpg" }) {
          ...simpleImage
        }
      }
    `,
  )

  return (
    <StyledSection id="section-5" title="Tasting Video">
      <StyledContainer>
        <H2>
          123 Organic Tequila Tasting with Master Distiller
          <br />
          David Ravandi
        </H2>
        <ImageContainer>
          <Img src={poster.childImageSharp.fluid.src} />
          <YoutubeIcon
            href="https://www.youtube.com/watch?v=3oOao1l8ttw&t=1s"
            target="_blank"
          />
          <LogoContainer>
            <LogoImg src={logo} />
          </LogoContainer>
        </ImageContainer>
      </StyledContainer>
    </StyledSection>
  )
}

const StyledSection = styled(FullScreenContainer)`
  background: white;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 64px 32px;
  max-height: 100%;
  overflow: scroll;
`

const H2 = styled.h2`
  font-family: "D-DIN Condensed", Helvetica;
  font-weight: bold;
  font-size: 37px;
  line-height: 37px;
  letter-spacing: 1.5px;
  margin: 0 auto 32px;
  text-align: center;
  color: ${Colors.BROWN};
`

const ImageContainer = styled.div`
  position: relative;
`

const Img = styled.img`
  width: 980px;
  max-width: 100%;
  margin: 0;
`

const LogoContainer = styled.div`
  padding: 8px;
  border-radius: 100px;
  width: 90px;
  height: 90px;
  position: absolute;
  top: 24px;
  left: 24px;

  ${mediaQueries("tablet")`
        width: 50px;
        height: 50px;
        top: 12px;
        left: 12px;
    `}
`

const LogoImg = styled.img`
  ${centered};
  width: 36;
  height: auto;
  object-fit: contain;
  image-rendering: -webkit-optimize-contrast;

  ${mediaQueries("tablet")`
        width: 24px;
    `}
`

const YoutubeIcon = styled.a`
  ${centered};
  width: 130px;
  height: 80px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${YoutubeDark});
  opacity: 0.9;

  &:hover {
    opacity: 1;
    background-image: url(${YoutubeColor});
  }

  ${mediaQueries("tablet")`
        width: 60px;
        height: 37px;
    `}
`

export default Section
