import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { HomeSectionQueryQuery } from "../../graphql"
import { aspectRatioMediaQueries, mediaQueries } from "../../styles/mediaQueries"
import FullScreenContainer from '../full-screen-container'

import LogoDark from '../../images/logo.png';
import LogoLight from '../../images/logo_light.png';
import { useOnScreen } from "../../utilities/hooks"
import DynamicImage from "../dynamic-image"
import Modal from "../modal"
import { headline2, paragraph } from "../../styles/typography"
import { centered } from "../../styles/positions"

import logo from '../../images/logo.svg';
import logoLight from '../../images/logo_light.svg';

interface HomeProps {
}

const infoText = `
    <div>
    <p>123 Organic Tequila begins its journey in the same ancient <br/>
    volcanic soils that once sustained the Aztecs. Our agave <br/>
    plantations near the town of Tequila have been sustainably <br/>
    cultivated for centuries and from these pristine estate fields <br/>
    comes the expressive character of 123 Organic Tequila <br/>
    (Uno Dos Tres).
    </p>
    <p>
    The origins of tequila are found in legend of the Aztec <br/>
    goddess Mayahuel and her story is depicted on our <br/>
    labels. Mayahuel dwelled in the sky with the star <br/>
    demons, the Tzitzimime, that are said to battle the <br/>
    sun each dusk and dawn. When it was discovered that <br/>
    she had eloped with the feathered snake God Quetzacoatl, <br/>
    the Tzitzimime descended to earth in pursuit.
    </p>

    <p>
    <span class="indent indent--10">In an effort to escape, the couple disguised themselves</span><br/>
    <span class="indent indent--12">as the forked branches of a tree but when Mayahuel’s </span><br/>
    <span class="indent indent--12">hiding place was revealed she could not escape the </span><br/>
    <span class="indent indent--10">wrath of the Tzitzimine.</span><br/>
    </p></div>

    <div>
    <p>
    <span class="indent indent--5">With a heavy heart, Quetzacoatl buried her where she fell. </span><br/>
    <span class="indent indent--6">The snake that adorns the seal on every bottle of 123 Organic</span><br/>
    <span class="indent indent--6">Tequila serves as a symbol of protection both for the sacred field where</span><br/>
    <span class="indent indent--5">Mayahuel was laid to rest and the agave plants that sprang forth there.</span><br/>
    </p>
    <p>
    <span class="indent indent--10">The snake that adorns the seal on every bottle of 123 Organic</span><br/>
    <span class="indent indent--11">Tequila serves as a symbol of protection both for the sacred </span><br/>
    <span class="indent indent--12">field where Mayahuel was laid to rest and the agave plants </span><br/>
    <span class="indent indent--11">that sprang forth there.</span><br/>
    </p>
    <p>
    <span class="indent indent--10">Today, organic certification is your guarantee that</span><br/>
    <span class="indent indent--9">our plantations are farmed without pesticides or </span><br/>
    <span class="indent indent--8">synthetic fertilizers and that every small batch of </span><br/>
    <span class="indent indent--3">tequila is produced with renewable resources and </span><br/>
    <span class="indent indent--1">hand bottled at the estate.</span><br/>
    </p>
    <p>
Mayahuel’s offspring, the 400 tipsy rabbits, serve <br/>
as a reminder to consume 123 Organic Tequila <br/>
in moderation.</p>
</div>

`

const Home = ({ }: HomeProps) => {
    const { background, story }: HomeSectionQueryQuery = useStaticQuery(
        graphql`
            query HomeSectionQuery {
                background: file(
                    relativePath: { eq: "home/bg.jpg"}
                ) {
                    ...backgroundImage
                }
                story: file(
                    relativePath: { eq: "home/123_story.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 3400, quality: 100) {
                        ...image
                        }
                    }
                }
            }
        `,
    )

    const [open, setOpen] = React.useState(false);
    const [logoHovered, setLogoHovered] = React.useState(false);

    const ref = React.useRef()
    const {y} = useOnScreen(ref);

    let backgroundOffest = 0;
    if (typeof window !== `undefined`) {
        backgroundOffest = Math.max(-(window.innerHeight * 0.5), (window.innerHeight * 0.5) * (y / window.innerHeight))
    }



    return (
        <div ref={ref}>
        <StyledHome id="home" backgroundImage={background.childImageSharp.fluid.src} containerClassName="home" ignoreSticky={true} style={{
            backgroundPositionY: backgroundOffest
        }}>
            <Logo onClick={() => setOpen(true)} onMouseEnter={() => setLogoHovered(true)} onMouseLeave={() => setLogoHovered(false)} src={logoHovered ? logoLight : logo}/>
        </StyledHome>

        <Modal isOpen={open} onClose={() => setOpen(false)} buttonColor="black">
                <StyledModalSection>
                    <ModalContent>
                        <Poster>
                        <ModalImage srcSet={story.childImageSharp.fluid.srcSet} sizes="(max-width: 768px) 66.67vh,
                        3500px" />
                        <div className="copy-container">
                            <h2><span className="bold">123 Organic Tequila</span> (Uno Dos Tres)</h2>
                            <div className="modal-copy" dangerouslySetInnerHTML={{__html: infoText}} />
                        </div>
                        </Poster>
                    </ModalContent>
                </StyledModalSection>
            </Modal>
        </div>
    )
}

const StyledHome = styled(FullScreenContainer)`
    height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;

    ${aspectRatioMediaQueries("homeBreak")`
        padding-left: 80px;
    `};

    ${mediaQueries("tablet")`
        padding-left: 0;
        background-position: 48% center;
    `};

    .home {
        ${mediaQueries('tablet')`
            height: 100vh;
        `}
    }
`

const Logo = styled.img`
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%);
    width: 168px;
    height: 171px;
    cursor: pointer;
    object-size: contain;
`

const StyledModalSection = styled.div`
    align-items: center;
    overflow: auto;
    width: 100vw;
    background: white;
`
const Poster = styled.div`
    position: relative;
`
const ModalContent = styled.div`
    position: relative; 
    width: 1750px;
    height: 100vh;
    background: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    margin: auto;

    ${mediaQueries('tablet')`
        width: auto;
    `}

    .copy-container {
        position: absolute;
        left: 12.5%;
        top: 50%;
        transform: translateY(-50%);
        width: 75%;
        color: black;
        padding-bottom: 5.4%;

        h2 {
            ${headline2};
            font-size: 32.5px;
            font-weight: normal;
            text-align: left;
            margin-bottom: 1.84em;
            display: inline-block;
            margin-left: 27%;
            .bold {
                font-weight: bold;
            }

            @media screen and (max-height: 957px) {
                font-size: calc((100vh - 140px) * 0.04)
            }
        }
    }

    .modal-copy {
        display: flex;
        
        div {
            flex: 1;

            &:nth-child(1) {
                flex: 0 0 39%;
                margin-right: 11%;
            }

            &:nth-child(2) {
                flex: 0 0 46%;
            }
        }
        p {
            ${paragraph};
            font-size: 19px;
            line-height: 1.34em;
            margin: 0;
            letter-spacing: 0;

            &:not(:last-child) {
                margin-bottom: 0.75em;
            }

            @media screen and (max-height: 957px) {
                font-size: calc((100vh - 140px) * 0.0232)
            }

            .indent {
                display: inline-block;
                &--1 {
                    text-indent: .365em; 
                }
                &--2 {
                    text-indent: .73em; 
                }
                &--3 {
                    text-indent: 1.095em; 
                }
                &--4 {
                    text-indent: 1.46em; 
                }
                &--5 {
                    text-indent: 1.825em; 
                }
                &--6 {
                    text-indent: 2.19em; 
                }
                &--7 {
                    text-indent: 2.555em; 
                }
                &--8 {
                    text-indent: 2.92em; 
                }
                &--9 {
                    text-indent: 3.285em; 
                }
                &--10 {
                    text-indent: 3.65em; 
                }
                &--11 {
                    text-indent: 4.015em; 
                }
                &--12 {
                    text-indent: 4.38em; 
                }
            }
        }
    }
`

const ModalImage = styled.img`
    max-width: initial;
    max-width: 1750px;
    max-height: calc(100vh - 140px);    
    width: 100%;
    background-color: white;
    object-fit: contain;
    flex: 0;
    margin: 0;
    image-rendering: -webkit-optimize-contrast;

    ${mediaQueries("tablet")`
        height: calc(100vh - 140px);
        width: auto;
    `};
`

export default Home
