import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionFourQueryQuery } from "../../graphql"
import Modal from "../modal"
import FullScreenContainer from "../full-screen-container"
import DynamicImage from "../dynamic-image"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { Colors } from "../../styles/colors"
import { centered, fullWidth } from "../../styles/positions"
import { breakpoints, mediaQueries } from "../../styles/mediaQueries"
import ratingImage from "../../images/section-4/rating.png"
import { useWindowResize } from "../../utilities/hooks"

interface SectionProps {}

const quotes = [
  {
    text: "Best Overall: 123 Certified Organic Blanco",
    source: "Liquor.com",
    link: "https://www.liquor.com/best-blanco-tequilas-5080770",
    grid: `1 / 1 / 2 / 13`,
    size: "large",
  },
  {
    text: "“complex and delicious”",
    source: "The Tequila Tourist.com",
    grid: `2 / 1 / 3 / 5`,
    size: "medium",
    transform: "translate(0%, -15%)",
  },
  {
    text: "The 18 Best Tequilas in the World",
    source: "Men's Journal",
    grid: `2 / 5 / 3 / 13`,
    size: "large",
    href:
      "https://www.mensjournal.com/food-drink/the-18-best-tequilas-in-the-world-20131217/gran-patron-platinum/",
    transform: "translate(0%, 5%)",
  },
  {
    text: "96 Points",
    grid: `3 / 1 / 4 / 3`,
    size: "large",
    image: ratingImage,
    transform: "translate(-30%, -10%)",
  },
  {
    text: "“Some of the cleanest and purest tequilas we’ve ever tasted.”",
    source: "Bounty Hunter",
    grid: `3 / 3 / 4 / 9`,
    size: "large",
    transform: "translate(2.5%, 0%)",
  },
  {
    htmlText: "“(Tres)...Impressive...<br />a crowd pleaser.”",
    source: "The Tequila Tourist.com",
    grid: `3 / 9 / 4 / 13`,
    size: "medium",
    transform: "translate(15%, -10%)",
  },
  {
    text: "“...Ravandi knows his stuff...”",
    source: "Drinkhacker.com",
    grid: `4 / 1 / 5 / 6`,
    size: "medium",
    transform: "translate(-25%)",
  },
  {
    text: "“Head Over Heels in Love.”",
    source: "Tasting Panel Publisher’s Pick",
    grid: `4 / 5 / 6 / 8`,
    size: "large",
    transform: "translate(5%, 10%)",
  },
  {
    text: "“...a must for anyone who loves tequila...”",
    source: "The Gourment Review.com",
    grid: `4 / 8 / 5 / 13`,
    size: "small",
    indent: true,
    transform: "translate(10%, -10%)",
  },
  {
    text: "“...a you can feel good about.”",
    source: "Natural Health Magazine.com",
    grid: `5 / 1 / 6 / 5`,
    size: "small",
    transform: "translate(-10%)",
  },
  {
    htmlText:
      "“...impeccable bouquets <br/> and brilliant agave-induced flavors”",
    source: "The Gourment Review.com",
    grid: `5 / 8 / 6 / 13`,
    size: "small",
    transform: "translate(20%, 0%)",
  },
  {
    text: "“(Uno)...I could drink this all night long.”",
    source: "Taste Tequila.com",
    grid: `6 / 1 / 7 / 9`,
    size: "medium",
    transform: "translate(-30%, 0%)",
  },
  {
    htmlText:
      "“(Dos)...the agave shines through...<br/> appreciate the delicate approach <br/> to aging here.”",
    source: "Taste Tequila.com",
    grid: `6 / 7 / 7 / 13`,
    size: "medium",
    transform: "translate(0%, 20%)",
  },
]

const Section = ({}: SectionProps) => {
  const { bg }: SectionFourQueryQuery = useStaticQuery(
    graphql`
      query SectionFourQuery {
        bg: file(relativePath: { eq: "section-4/bg.jpg" }) {
          ...backgroundImage
        }
      }
    `,
  )

  const windowSize = useWindowResize()
  const isTablet = windowSize.width <= breakpoints.tablet

  return (
    <StyledSection
      id="section-4"
      title="Press"
      backgroundImage={bg.childImageSharp.fluid.src}
    >
      <SectionContainer>
        {quotes.map(
          ({
            text,
            htmlText,
            source,
            size,
            indent,
            transform,
            grid,
            image,
            link,
          }) => {
            const press = (
              <Press style={{ transform: isTablet ? undefined : transform }}>
                {htmlText ? (
                  <PressP
                    className={`${size} ${indent ? "indent" : ""}`}
                    dangerouslySetInnerHTML={{ __html: htmlText }}
                  />
                ) : (
                  <PressP className={`${size} ${indent ? "indent" : ""}`}>
                    {text}
                  </PressP>
                )}
                <PressP className={size === "large" ? "small" : "tiny"}>
                  {image ? <img src={image} /> : "- " + source}
                </PressP>
              </Press>
            )
            return link ? (
              <a
                className="press-container"
                href={link}
                style={{ gridArea: isTablet ? undefined : grid }}
                target="_blank"
              >
                {press}
              </a>
            ) : (
              <div
                className="press-container"
                style={{ gridArea: isTablet ? undefined : grid }}
              >
                {press}
              </div>
            )
          },
        )}
      </SectionContainer>
    </StyledSection>
  )
}

const StyledSection = styled(FullScreenContainer)`
  height: 100vh;
  display: flex;
  position: relative;
  background-attachment: fixed !important;
  justify-content: center;
  align-items: center;

  ${mediaQueries("medium")`
        height: auto;
    `};
`

const SectionContainer = styled.div`
  max-height: 100vh;
  position: relative;
  width: 1400px;
  max-width: calc(100vw - 136px);
  height: 800px;
  grid-template-rows: repeat(7, 1fr);
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  margin-top: 64px;

  a {
    text-decoration: none;
  }

  .press-container {
    display: flex;
    align-items: center;
    justify-content: center;

    ${mediaQueries("medium")`
            flex: 0 0 50%;
            padding: 16px;
            max-width: 100%;
        `};

    ${mediaQueries("tablet")`
            flex: 0 0 100%;
            padding: 16px;
        `};
  }

  ${mediaQueries("medium")`
        max-height: initial;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        margin: 32px auto;
    `};
`

const Press = styled.div`
  font-size: 48px;

  ${mediaQueries("maxHeadline")`
        font-size: 32px;
    `};
  ${mediaQueries("medium")`
        font-size: 24px;
    `};
`

const PressP = styled.p`
  color: white;
  font-family: times, serif;
  -webkit-font-smoothing: inherit;
  margin-bottom: 0.2em;
  text-align: center;
  width: auto;
  display: block;
  line-height: 1em;

  &.indent {
    text-indent: -0.5em;
  }

  &.large {
    font-size: 1em;
  }
  &.medium {
    font-size: 0.79em;
  }
  &.small {
    font-size: 0.5625em;
  }
  &.tiny {
    font-size: 0.35em;
    ${mediaQueries("medium")`
            font-size: .5625em;
        `};
  }
`

export default Section
