import React from "react"
import Home from "../components/home/home"
import Section1 from "../components/section-1"
import Section2 from "../components/section-2"
import Section3 from "../components/section-3"
import Section4 from "../components/section-4"
import Section5 from "../components/section-5"
import Section6 from "../components/section-6"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <Home />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </Layout>
  )
}

export default IndexPage
