import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { mediaQueries } from "../styles/mediaQueries";

const StyledModal = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: opacity 0.4s ease-out;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: ${props => props.isOpen ? 1 : 0};
  pointer-events: all;
  &.hidden {
    pointer-events: none;
  }
`

const ModalContent = styled.div`
  position: relative;
  max-width: 100%;
`

const ModalBackground = styled.div`
  background: rgba(0,0,0,0.9);
  cursor: pointer;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
`

const StyledClose = styled.button`
  position: fixed;
  background: none;
  border: none;
  top: 48px;
  right: 48px;
  width: 20px;
  height: 20px;
  transform: translate(50%, -50%);
  z-index: 1;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  &:hover {
    &::before, &::after {
      opacity: 0.5;
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: ${props => props.color || 'white'};
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
`

interface ModalProps {
  isOpen?: boolean,
  onClose?: () => void,
  onHidden?: () => void,
  buttonColor?: StaticRange,
}

class Modal extends React.Component<ModalProps> {
  constructor(props) {
    super(props)
    this.state = {
      isHidden: true
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this.props.isOpen) {
      document.body.style.overflow = "hidden";
      this.setState({
        isHidden: false
      })
    } else if (!nextProps.isOpen && this.props.isOpen) {
      document.body.style.removeProperty("overflow");
    }
  }

  render() {
    const { isOpen, children, buttonColor } = this.props;
    const portal =
      typeof document !== `undefined`
        ? document.getElementById("modal-container")
        : null;
    const modal = (
      <div>
        <StyledModal isOpen={isOpen} className={`${isOpen ? 'open' : 'closed'} ${this.state.isHidden ? 'hidden' : ''}`}>
          <ModalBackground onClick={() => this.handleClose()} />
          <ModalContent>
            <StyledClose onClick={() => this.handleClose()} color={buttonColor} />
            {children}
          </ModalContent>
        </StyledModal>
      </div>
    );

    return portal ? ReactDOM.createPortal(modal, portal) : <div />;
  }

  handleClose () {
    clearTimeout(this.onHiddenTimeout);
    const {onClose, onHidden} = this.props;
    if (onClose) {
      onClose()
    }
    this.onHiddenTimeout = setTimeout(() => {
      if (onHidden) {
        onHidden();
      }
      this.setState({
        isHidden: true
      })
    }, 400)
  }
}

export default Modal;
