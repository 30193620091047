import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionTwoQueryQuery } from "../../graphql"
import FullScreenContainer from "../full-screen-container"
import DynamicImage from "../dynamic-image"
import Modal from "../modal"
import { mediaQueries } from "../../styles/mediaQueries"
import { headline2, paragraph } from "../../styles/typography"

interface SectionProps {}

const Section = ({}: SectionProps) => {
  const {
    bottle1,
    bottle2,
    bottle3,
    bottle4,
    bottle5,
    modal1,
    modal2,
    modal3,
    modal4,
    modal5,
  }: SectionTwoQueryQuery = useStaticQuery(
    graphql`
      query SectionTwoQuery {
        bottle1: file(relativePath: { eq: "section-2/bottle-1.png" }) {
          ...dynamicImage
        }
        bottle2: file(relativePath: { eq: "section-2/bottle-2.png" }) {
          ...dynamicImage
        }
        bottle3: file(relativePath: { eq: "section-2/bottle-3.png" }) {
          ...dynamicImage
        }
        bottle4: file(relativePath: { eq: "section-2/bottle-4.png" }) {
          ...dynamicImage
        }
        bottle5: file(relativePath: { eq: "section-2/bottle-5.png" }) {
          ...dynamicImage
        }
        modal1: file(relativePath: { eq: "section-2/modal1.jpg" }) {
          ...posterImage
        }
        modal2: file(relativePath: { eq: "section-2/modal2.jpg" }) {
          ...posterImage
        }
        modal3: file(relativePath: { eq: "section-2/modal3.jpg" }) {
          ...posterImage
        }
        modal4: file(relativePath: { eq: "section-2/modal4.jpg" }) {
          ...posterImage
        }
        modal5: file(relativePath: { eq: "section-2/modal5.jpg" }) {
          ...posterImage
        }
      }
    `,
  )

  const [open, setOpen] = React.useState(false)
  const [active, setActive] = React.useState(undefined)

  React.useEffect(() => {
    if (!open && active !== undefined) {
      setOpen(true)
    }
  }, [active])
  const modals = [
    {
      description: `
                <h2>123 Organic Tequila Blanco (Uno)</h2><br/>
                <h3>Tasting Notes</h3>
                <quote><i>Unaged and clean with intense aromas of fresh agave. Vibrant with lemon peel, black pepper, and minerals. Smooth as silk, with a slight sweet kick on a lengthy finish, which is so easy it's criminal.</i><br/>
                <b><i>“96 Points” Tasting Panel Magazine</b></i></quote>
                <br/>
                <p>The stars dance in the sky for as many as ten years before the organic agaves are harvested for 123 Organic Blanco (Uno) tequila, the pure mother spirit from which Reposado (Dos) and Añejo (Tres) tequilas are created. Under the watchful gaze of the guardian snake (coatl), the mature agaves are hand-harvested and the hearts are slowly cooked in stone ovens before being pressed to release their sugary juice. After a natural fermentation and small-batch distillation, Blanco tequila is born.</p>
            `,
      image: modal1,
    },
    {
      description: `
                <h2>123 Organic Tequila Reposado (Dos)</h2><br/>
                <h3>Tasting Notes</h3>
                <quote><i>Aged 6 months in oak, which gives it a very light color and no real hint of wood. Salted caramel, crème brûlée, and toffee notes. There's agave on the nose, but it quickly dissipates as you head for a light dessert course, complete with minty anise finish. Most complex tequila in the lineup.</i><br/>
                <b><i>“96 Points” Tasting Panel Magazine</b></i></quote>
                <br/>
                <p>As the sun and moon meet in the sky above the agave plantation, two stealthy jaguars (miztli) roam the fields in search of their elusive prey. During their silent hunts, Reposado (Dos) tequila rests in white oak barrels for six months before its timely release. This complex spirit reveals aromatic raw and cooked agave tempered by the warmth of oak aging; a combination equally at home in cocktails and as a sipping tequila making it the most versatile of the 123 Organic Tequila styles.</p>
            `,
      image: modal2,
    },
    {
      description: `
                <h2>123 Organic Tequila Anejo (Tres)</h2><br/>
                <h3>Tasting Notes</h3>
                <quote><i>After eighteen months in oak, Tres' wood character is more prominent, and on first blush it is heady on the nose with tannin and wood oil notes. This fades after time in the glass, revealing richer versions of those characteristics in the Reposado: caramel and some chocolate notes. A lovely anejo, it really opens up when you give it time.</i><br/>
                <b><i>“96 Points” Tasting Panel Magazine</b></i></quote>
                <br/>
                <p>On a morning when three brilliant roosters (tototl) greet the dawn with hopeful songs of renewal, mature agave plants at the hacienda are ready to be hand harvested and Organic Blanco tequila awakens from an eighteen-month slumber in white oak barrels to be reborn as Añejo (Tres) Tequila. Just as the rooster has long been a symbol of virility and power in Mexican folklore, the complex aromatic bouquet and deep golden hue of Añejo (Tres) Tequila are signs of its superior quality and lengthy aging.
                </p>
            `,
      image: modal3,
    },
    {
      description: `
                <h2>123 Organic Extra Anejo (Diablito)</h2><br/>
                <h3>Tasting Notes</h3>
                <quote><i>Aged for 40 months in new French Limousin oak barrels, this “little devil” of a tequila boasts a rich, creamy body that greets the nose with vanilla and black pepper scents. Flavors of vanilla, spices, and roasted agave then settle in before a tart, citrusy, long-lasting finish hints at the tequila’s complexity and easy-drinking nature.</i><br/>
                <b><i>“96 Points” Anthony Dias Blue</b></i></quote>
                <br/>
                <p>Devilish to the last drop.<br/><br/>
                This rich, aged tequila was originally created by Ravandi for his personal collection. In 2014 he released 1,000 bottles and, since then, it has become a limited, annual release of just 2,000 bottles.<br/><br/>
                Diablito begins its journey in the iron-rich Tierra Roja soils of a pristine estate that we've designated solely for the allocated production of 123 Organic Extra Añejo. The result of a patient process that nurtures the organic agave through a slow maturation, Diablito is an exceptional expression of Jalisco's Amatitan region which is home to the only spirit that can bear the name tequila.<br/><br/>
                Like intensely expressive wines that are grown at high elevations, the extreme growing conditions found on our estate, which sits at the highest elevation in the region, produce complexly-flavored tequila that is ideally suited to lengthy aging.<br/><br/>
                In the pursuit of perfection it's often said "the devil is in the details," a sentiment that captures the singular spirit of 123 Organic Extra Añejo (Diablito). The Diablito on our label signifies our absolute passion for perfection and the devilishly good tequila you'll discover in our bottles.<br/><br/>
                </p>
            `,
      image: modal4,
    },
    {
      description: `
            <h2>123 Organic Extra Anejo (Diablito Rojo)</h2><br/>
            <p>123 Organic Tequila Extra Anejo “Diablito” was originally created as a coveted release from Master Tequilero David Ravandi’s private collection. He now proudly presents his extremely limited new release “Diablito Rojo.”
            <br/>
            <br/>
            <quote><i><b>“99 Points” Tasting Panel Magazine</b></i></quote>
            <br/>

            123 Organic Tequila Extra Anejo “Diablito Rojo” is handcrafted from sustainably cultivated single estate-grown 100% organic blue agave flourishing in 6,000 ft. in mineral-rich red volcanic soils. After aging in French white oak Limousine barrels for no less than seven years, it is masterfully finished with an additional six months in French Limousine oak barrels that previously held one of Napa Valley’s premiere California Cabernet Sauvignon wines.
            <br/><br/>
            This exquisite aging regime heightens the rich spicy flavors of our “Diablito Rojo” with incredible secondary and tertiary notes of ultra-premium Cabernet Sauvignon, producing a uniquely complex tequila with delicious aromas and a rich silky texture.
            <br/><br/>
            With an extremely limited 2,000 bottle release, Diablito Rojo defies imagination; it demands tasting.
            </p>
        `,
      image: modal5,
    },
  ]
  return (
    <StyledSection id="section-2" title="Tequilas">
      <Container onClick={() => setActive(0)}>
        <BottleImage image={bottle1} />
      </Container>
      <Container onClick={() => setActive(1)}>
        <BottleImage image={bottle2} />
      </Container>
      <Container onClick={() => setActive(2)}>
        <BottleImage image={bottle3} />
      </Container>
      <Container onClick={() => setActive(3)}>
        <BottleImage image={bottle4} />
      </Container>
      <Container onClick={() => setActive(4)}>
        <BottleImage image={bottle5} />
      </Container>
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        onHidden={() => setActive(undefined)}
        buttonColor="black"
      >
        <StyledModalSection>
          <ModalContent>
            {active !== undefined && (
              <>
                <div
                  className={`modal-copy modal-copy--${active}`}
                  dangerouslySetInnerHTML={{
                    __html: modals[active].description,
                  }}
                />
                <div className="image-container">
                  <FixedImage
                    srcSet={modals[active].image.childImageSharp.fluid.srcSet}
                    sizes="(max-width: 768px) 100vw,
                        3500px"
                  />
                </div>
              </>
            )}
          </ModalContent>
        </StyledModalSection>
      </Modal>
    </StyledSection>
  )
}

const StyledSection = styled(FullScreenContainer)`
  height: 100vh;
  display: flex;
  background: white;
  ${mediaQueries("tablet")`
      height: 100vw;
    `};
`
const Container = styled.div`
  height: 100vh;
  flex: 1 1 25%;
  max-width: 260px;
  ${mediaQueries("tablet")`
      height: 100vw;
    `};
`

const BottleImage = styled(DynamicImage)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
`

const StyledModalSection = styled.div`
  align-items: center;
  max-width: 100vw;
  max-height: 100vh;
  overflow: auto;
  width: 100vw;
  background: white;
`

const ModalContent = styled.div`
  position: relative;
  width: 1750px;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  margin: auto;

  ${mediaQueries("tablet")`
        width: 100%;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;

        .image-container {
            width: 100%;
            position: relative;
            margin-bottom: 20px;
            &:after {
                content: '';
                display: block;
                padding-top: 70%;
            }
        }
    `}

  .modal-copy {
    position: absolute;
    color: black;
    text-align: center;
    padding: 32px 4%;
    width: 45.6%;
    max-width: 105vh;
    max-height: 100vh;
    overflow: scroll;

    ${mediaQueries("tablet")`
            position: relative;
            width: 100%;
            padding: 100px 16px 32px;
            max-height: initial;
            overflow: initial;
        `}

    h2 {
      ${headline2};
      ${mediaQueries("tablet")`
                font-size: 24px;
            `}
    }

    h3 {
      ${headline2};
      font-size: 24px;
      line-height: 1.55em;

      ${mediaQueries("tablet")`
                font-size: 18px;
            `}
    }

    quote {
      ${paragraph};
      font-size: 22px;
      line-height: 1.6em;
      margin-top: 0.35em;
      letter-spacing: -0.015em;
      display: block;
      ${mediaQueries("tablet")`
                font-size: 16px;
            `}
    }

    p {
      ${paragraph};
      font-size: 23px;
      margin-top: -0.2em;
      margin-bottom: 0;
      letter-spacing: 0.01em;
      line-height: 1.5655em;
      ${mediaQueries("tablet")`
                font-size: 16px;
            `}
    }

    &--0 {
    }
    &--3,
    &--4 {
      quote {
        font-size: 17px;
        line-height: 1.3em;
        ${mediaQueries("tablet")`
                    font-size: 16px;
                `}
      }

      p {
        font-size: 17px;
        line-height: 1.3em;
        ${mediaQueries("tablet")`
                    font-size: 16px;
                `}
      }
    }
  }
`

const FixedImage = styled.img`
  max-width: initial;
  max-width: 1750px;
  max-height: 100vh;
  width: 100%;
  background-color: white;
  object-fit: contain;
  flex: 0;
  margin: 0;
  image-rendering: -webkit-optimize-contrast;
  ${mediaQueries("tablet")`
        position: absolute;
        left: 0;
        top: 0;
        width: 154%;
        height: 100%;
        object-fit: cover;
        object-position: right;
        transform: translate(-39.5%);
    `};
`

export default Section
