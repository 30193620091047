import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionThreeQueryQuery } from "../../graphql"
import Modal from '../modal'
import FullScreenContainer from '../full-screen-container'
import DynamicImage from '../dynamic-image'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Colors } from "../../styles/colors"
import { centered, fullWidth } from "../../styles/positions"
import { mediaQueries } from "../../styles/mediaQueries"
import { headline1, headline2, paragraph } from "../../styles/typography"

import recipe1FullRes from '../../images/section-3/recipe1.jpg'
import recipe2FullRes from '../../images/section-3/recipe2.jpg'
import recipe3FullRes from '../../images/section-3/recipe3.jpg'

interface SectionProps {
}

const Section = ({ }: SectionProps) => {
    const { recipe1, recipe1Blank, recipe2, recipe2Blank, recipe3, recipe3Blank }: SectionThreeQueryQuery = useStaticQuery(
        graphql`
            query SectionThreeQuery {
                recipe1: file(
                    relativePath: { eq: "section-3/recipe1.jpg"}
                ) {
                    ...posterImage
                },
                recipe1Blank: file(
                    relativePath: { eq: "section-3/recipe1_blank.jpg"}
                ) {
                    ...posterImage
                },
                recipe2: file(
                    relativePath: { eq: "section-3/recipe2.jpg"}
                ) {
                    ...posterImage
                },
                recipe2Blank: file(
                    relativePath: { eq: "section-3/recipe2_blank.jpg"}
                ) {
                    ...posterImage
                },
                recipe3: file(
                    relativePath: { eq: "section-3/recipe3.jpg"}
                ) {
                    ...posterImage
                },
                recipe3Blank: file(
                    relativePath: { eq: "section-3/recipe3_blank.jpg"}
                ) {
                    ...posterImage
                },
            }
        `,
    )

    const [active, setActive] = React.useState(0);
    const [modalActive, setModalActive] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [increasing, setIncreasing] = React.useState(true);
    const slides = [
        {
            imageBlank: recipe1Blank,
            image: recipe1,
            imageFullRes: recipe1FullRes,
            copy: `
                <h2>Rosita</h2>
                <p><i>
                    2 oz 123 Organic Tequila Blanco (Uno)<br/><br/>
                    2 oz Sweet Vermouth<br/>
                    2 oz Campari<br/><br/>
                    Method: On the rocks with a lemon twist
                </i></p>
            `,
        },
        {
            
            imageBlank: recipe2Blank,
            image: recipe2,
            imageFullRes: recipe2FullRes,
            copy: `
                <h2>Paloma</h2>
                <p><i>
                    2 oz 123 Organic Tequila Reposado (Dos)<br/><br/>
                    3/4 oz Elderflower Liqueur<br/>
                    3/4 oz Grapefruit Juice<br/>
                    3/4 oz Lime Juice<br/>
                    3/4 oz Agave Syrup (1 to 1)<br/><br/>
                    Method: In a mixing glass, add all with ice.<br/> 
                    Shake & strain over fresh ice in a tall glass.<br/><br/>
                    Garnish: Grapefruit wheels in a glass
                </i></p>
            `,
        },
        {
            
            imageBlank: recipe3Blank,
            image: recipe3,
            imageFullRes: recipe3FullRes,
            copy: `
                <h2>Mexico City <br/><span className="subtitle">(CDMX)</span></h2>
                <p><i>
                    2.5 oz 123 Organic Tequila Añejo (Tres)<br/><br/>
                    3/4 oz Sweet Vermouth<br/>
                    1 Dash Luxardo Maraschino Cherry Liqueur<br/>
                    2 Dash Angostura Bitters<br/>
                    2 Luxardo Maraschino Cherries<br/><br/>
                    Method: Pour the Añejo Tequila, Sweet Vermouth, <br/>
                    and Cherry Liqueur in a cocktail shaker<br/><br/>
                    Shake & strain into the chilled martini glass <br/>
                    or on the rocks in a tumbler<br/><br/>
                    Garnish: Spoon two cherries in a chilled glass
                </i></p>
            `,
        },
    ]

    React.useEffect(() => {
        if (modalActive !== active) {
            setModalActive(active)
        }
    }, [active])

    React.useEffect(() => {
        if (active !== modalActive) {
            setActive(modalActive)
        }
    }, [modalActive])

    const renderSlide = (index: number) => {
        return (
            <Container>
                <RecipeImage srcSet={slides[index].imageBlank.childImageSharp.fluid.srcSet} sizes="(max-width: 768px) 100vw,(max-width: 1280px) 1750px,
                        3500px" onClick={() => setOpen(true)} />
                <div className="recipe-copy" dangerouslySetInnerHTML={{__html: slides[index].copy}} />
            </Container>
        )
    }

    return (
        <StyledSection id="section-3" title="Cocktails">
            <SectionContainer>

                <TransitionGroup
                    className={`slider ${increasing ? 'slide-left' : 'slide-right'}`}
                >
                    <CSSTransition
                        key={active}
                        timeout={1000}
                        classNames={`slider__transition`}
                    >
                        {renderSlide(active)}
                    </CSSTransition>
                </TransitionGroup>

                <PrevButton onClick={(e: any) => {
                    setIncreasing(false);
                    if (active === 0) {
                        setActive(slides.length - 1);
                    } else {
                        setActive(active - 1);
                    }
                }}>
                    <ArrowLeft />
                </PrevButton>
                <NextButton onClick={(e: any) => {
                    setIncreasing(true);
                    if (active === slides.length - 1) {
                        setActive(0);
                    } else {
                        setActive(active + 1);
                    }
                }}>
                    <ArrowRight />
                </NextButton>
            </SectionContainer>

            <Modal isOpen={open} onClose={() => setOpen(false)}>
                <StyledModalSection>
                    <ModalContent>
                        <a href={slides[modalActive].imageFullRes} target="_blank">
                        <TransitionGroup
                            className={`modal-image`}
                        >
                            <CSSTransition
                                key={modalActive}
                                timeout={1000}
                                classNames={`modal-image__transition`}
                            >
                                    <ModalImage srcSet={slides[modalActive].image.childImageSharp.fluid.srcSet} sizes="1750px" />
                            </CSSTransition>
                        </TransitionGroup>
                        </a>
                        <ModalNavPrev onClick={(e: any) => {
                            setIncreasing(false);
                            if (modalActive === 0) {
                                setModalActive(slides.length - 1);
                            } else {
                                setModalActive(modalActive - 1);
                            }
                        }}>
                            <ArrowLeft />
                        </ModalNavPrev>
                        <ModalNavNext onClick={(e: any) => {
                            setIncreasing(true);
                            if (modalActive === slides.length - 1) {
                                setModalActive(0);
                            } else {
                                setModalActive(modalActive + 1);
                            }
                        }}>
                            <ArrowRight />
                        </ModalNavNext>
                    </ModalContent>
                </StyledModalSection>
            </Modal>
        </StyledSection>
    )
}

const SectionContainer = styled.div`
    max-height: 100vh;
    position: relative;
    width: 100vw;
    height: 100vh;

    ${mediaQueries("tablet")`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    `};
`

const StyledSection = styled(FullScreenContainer)`
    height: 100vh;
    display: flex;
    background: white;
    position: relative;

    ${mediaQueries("tablet")`
        height: auto;

        &:after {
            content: '';
            padding-top: 56.25%;
        }
    `};

`
const Container = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    transition-property: transform;
    transition-timing-function: cubic-bezier(0.25, 0, 0, 1);
    transition-duration: 1s;

    &.slider__transition-enter {
      transition-duration: 0;

      .slide-right & {
        transform: translateX(-100%);
      }
      .slide-left & {
        transform: translateX(100%);
      }
    }

    &.slider__transition-enter-active {
      z-index: 1;
      .slide-right &,
      .slide-left & {
        transition-timing-function: cubic-bezier(0.25, 0, 0, 1);
        transform: translateX(0%);
        transition-duration: 1s;
      }
    }

    &.slider__transition-exit {
      transition-timing-function: cubic-bezier(0.25, 0, 0, 1);

      .slide-left & {
        transform: translateX(-100%);
      }
      .slide-right & {
        transform: translateX(100%);
      }
    }

    .recipe-copy {
        position: absolute;
        color: black;
        text-align: center;
        padding: 0 64px;
        width: 50%;
        left: 0;

        ${mediaQueries('tablet')`
            padding: 0 12px 0 48px;
        `}

        h2 {
            ${headline2};
            font-size: 44px;
            margin-bottom: 1.5em;
            letter-spacing: 0.15em;
            ${mediaQueries('tablet')`
                font-size: calc((100vw - 60px) * 0.05 );
            `}
        }

        .subtitle {
            ${headline2};
            font-size: 24px;
            line-height: 1.55em;
            ${mediaQueries('tablet')`
                font-size: calc((100vw - 60px) * 0.04 );
            `}
        }

        p {
            ${paragraph};    
            font-size: 18px;
            margin-bottom: 0;
            letter-spacing: 0.01em;
            line-height: 1.7655em;
            ${mediaQueries('tablet')`
                font-size: calc((100vw - 60px) * 0.02 );
                line-height: 1.2em;
            `}
        }

        &--0 {
        }
    }
`

const SliderButton = styled.button`
    position: absolute;
    width: 32px;
    height: 134px;
    background: ${Colors.BLACK};
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    z-index: 2;
    &:hover {
        opacity: 0.75;
    }
`

const PrevButton = styled(SliderButton)`
    left: 0;
`

const NextButton = styled(SliderButton)`
    right: 0;
`

const Arrow = styled.div`
    width: 10px;
    height: 10px;
    position: relative;
    &:after {
        content: '';
        ${centered};
        width: 100%;
        height: 100%;
        border: 1px solid white;
        border-width: 0 1px 1px 0;
    }
`

const ArrowLeft = styled(Arrow)`
    &:after {
        transform: translate(-22.5%, -50%) rotate(135deg);
    }
`

const ArrowRight = styled(Arrow)`
&:after {
    transform: translate(-60%, -50%) rotate(-45deg);
}
`

const ModalNav = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer;
    &:hover {
        opacity: 0.75;
    }
`

const ModalNavPrev = styled(ModalNav)`
    right: calc(100% + 32px);
`
const ModalNavNext = styled(ModalNav)`
    left: calc(100% + 32px);
`

const RecipeImage = styled.img`
    ${centered};
    margin: 0;
    image-rendering: -webkit-optimize-contrast;
    height: 100vh;
    width: auto;
    object-fit: cover;
    background-color: white;

    ${mediaQueries('medium')`
        transform: translate(-43%,-50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
    `};
`

const StyledModalSection = styled.div`
    align-items: center;
    max-width: 100vw;
    max-height: 100vh;

    ${mediaQueries("maxHeadline")`
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: nowrap;
    `};

    ${mediaQueries("tablet")`
      max-width: calc(100vw - 80px);
    `};
`

const ModalContent = styled.div`
    position: relative;
`

const ModalImage = styled.img`
    max-width: calc(100vw - 160px);
    max-height: calc(100vh - 80px);
    background-color: white;
    object-fit: contain;

    &.modal-image__transition-enter {
        position: absolute;
        top: 0;
        left: 0;
        transition-duration: 0;
        opacity: 0;
      }
  
      &.modal-image__transition-enter-active {
        z-index: 1;
        opacity: 1;
        transition-delay: 0.5s;
        transition-duration: 0.5s;
      }
  
      &.modal-image__transition-exit {
        opacity: 0;
        transition-duration: 0.5s;
      }
`

export default Section
