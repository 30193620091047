
export const centered = () => {
    return () =>
        `
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        `;
    };
export const fullWidth = () => {
    return () =>
        `
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        `;
    };
    
    