import React, { useState } from "react"
import styled from "styled-components"
import { DynamicImageFragment } from "../graphql"

const PlaceholderImage = styled.img`
  position: relative;
  max-height: 100%;
  max-width: 100%;
  margin: 0;
  object-fit: contain;
`

interface DynamicImageProps {
  image: DynamicImageFragment,
  className?: string,
  id?: string,
  sizes?: string,
}

const DynamicImage = (props:DynamicImageProps) => {
  const {image} = props;
  const [loaded, setLoaded] = useState(false);
  const [mounted, setMounted] = useState(false);

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true)
      if (props.image.placeholder) {
        const fullRes = document.createElement('img');
        fullRes.addEventListener('load', () => setLoaded(true))
        fullRes.src = src;
      }
    }
  })

  // @ts-ignore
  const imageProps = Object.assign({}, props)
  delete imageProps.image;

  const src = image.childImageSharp.fluid.src;
  const srcset = image.childImageSharp.fluid.srcSet;

  return (
    <PlaceholderImage 
      {...imageProps} 
      src={!loaded && image.placeholder ? image.placeholder.fluid.base64 : src}
      srcSet={srcset}
    />

    // <StyleComponent id={id}>
    //   <ImageContainer>
    //     <PlaceholderImage className={loaded ? 'hidden' : 'visible'}>
    //       <img {...imageProps} src={DynamicImage.placeholder ? DynamicImage.placeholder.fluid.base64 : src} />
    //     </PlaceholderImage>
    //     {mounted && DynamicImage.placeholder && 
    //       <FullResImage className={loaded ? 'visible' : 'hidden'}>
    //         <img onLoad={() => setLoaded(true)} src={src} srcSet={srcset} {...imageProps} />
    //       </FullResImage>
    //     }
    //   </ImageContainer>
    // </StyleComponent>
  )
}

export default DynamicImage
